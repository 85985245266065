import { Component, OnInit } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { AppService } from "../../../servicios/app.service";
import { Pedido } from "../../../modelos/Pedido";
import { MatSnackBar } from "@angular/material/snack-bar";

@Component({
  selector: "app-selector-dvs-vencidos-sin-puntos",
  templateUrl: "./selector-dvs-vencidos-sin-puntos.page.html",
  styleUrls: ["./selector-dvs-vencidos-sin-puntos.page.scss"],
})
export class SelectorDvsVencidosSinPuntosPage implements OnInit {
  public mostrar: string = "todos";
  public pedidosDV: any;

  constructor(private modalController: ModalController, public appService: AppService, private snackBar: MatSnackBar) {}

  ngOnInit() {
    this.appService.cambiarPedidosMostrar("diseñadorVirtual");
  }

  //Adquiere el pedido DV desde el que se ha hecho click en el botón de adquirir
  public async solicitarDV(pedido) {
    this.appService.seleccionarPedido(pedido._id);
    this.cerrar();
  }

  public cerrar(): void {
    this.modalController.dismiss().then();
  }
}
