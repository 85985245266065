import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {DialogConfig} from '../../modelos/DialogConfig';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';

class HTMLInput {
  public id: string;
  public placeholder: string|null;
  public validators: Validators[];
  public type: string;
  public value: any;
  constructor(nId: string, nPlaceholder: string, nValidators: Validators[], nType: string, nValue: any) {
    this.id = nId;
    this.placeholder = nPlaceholder || null;
    this.validators = nValidators || [];
    this.type = nType || 'text';
    this.value = nValue;
  }
}
class HTMLSelect {
  public id: string;
  public placeholder: string|null;
  public validators: Validators[];
  public options: {text: string, value: any}[];
  public value: any;
  public multiple: boolean;
  constructor(nId: string, nPlaceholder: string, nValidators: Validators[], nOptions: {text: string, value: any}[], nValue: any, nMultiple: boolean) {
    this.id = nId;
    this.placeholder = nPlaceholder || null;
    this.validators = nValidators || [];
    this.options = nOptions || [];
    this.value = nValue;
    this.multiple = nMultiple != null ? nMultiple : false;
  }
}
type Control = HTMLInput|HTMLSelect;

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.page.html',
  styleUrls: ['./dialog.page.scss'],
})
export class DialogPage implements OnInit {

  @Input() data: DialogConfig;

  public textoPrincipal: string;
  public textoSecundario: {texto: string, css?: string}[];
  public controls: Control[];
  public btnConfirm: string;
  public btnCancel: string;
  public formGroup: FormGroup;

  constructor(private modalCtrl: ModalController,
              private snackBar: MatSnackBar,
              private fb: FormBuilder) {
  }

  ngOnInit() {
    this.textoPrincipal = this.data.textoPrincipal || 'Sin título';
    this.textoSecundario = this.data.textoSecundario || [];
    this.btnConfirm = this.data.btnConfirm || 'SI';
    this.btnCancel = this.data.btnCancel || 'NO';
    this.controls = [];

    if (this.data.controls) {
      for (const control of this.data.controls) {
        if (!this.controls.some(c => c.id === control.id)) {
          if (!('options' in control)) {
            this.controls.push(new HTMLInput(control.id, control.placeholder, control.validators, control.type, control.value));
          } else {
            this.controls.push(new HTMLSelect(control.id, control.placeholder, control.validators, control.options, control.value, control.multiple));
          }
        }
      }
    }

    this.formGroup = this.fb.group(
      this.controls.reduce((formGroup, control) => { formGroup[control.id] = [control.value, control.validators]; return formGroup; }, {})
    );
  }

  public confirmar(): void {
    if (this.comprobarFormulario()) {
      this.modalCtrl.dismiss({
        aceptar: true,
        inputValues: this.controls.reduce((iv, control) => { iv[control.id] = this.formGroup.controls[control.id].value; return iv; }, {})
      });
    } else {
      this.snackBar.open('Introduce todos los campos obligatorios', 'Ok', {duration: 3500});
    }
  }
  public cancelar(): void {
    this.modalCtrl.dismiss();
  }

  public isInput(control: Control): boolean {
    return control instanceof HTMLInput;
  }
  public isSelect(control: Control): boolean {
    return control instanceof HTMLSelect;
  }

  private comprobarFormulario(): boolean {
    this.formGroup.markAllAsTouched();
    return this.formGroup.valid;
  }
}
