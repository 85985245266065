import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login/login.module").then((m) => m.LoginPageModule),
  },
  {
    path: "busqueda-cliente",
    loadChildren: () =>
      import("./pages/busqueda-cliente/busqueda-cliente.module").then(
        (m) => m.BusquedaClientePageModule
      ),
  },
  {
    path: "asociacion-usuario3d-a-cliente",
    loadChildren: () =>
      import(
        "./pages/asociacion-usuario3d-a-cliente/asociacion-usuario3d-a-cliente.module"
      ).then((m) => m.AsociacionUsuario3dAClienteModule),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "notificacion-reasignado",
    // tslint:disable-next-line:max-line-length
    loadChildren: () =>
      import(
        "./pages/notificacion-reasignado/notificacion-reasignado.module"
      ).then((m) => m.NotificacionReasignadoPageModule),
  },
  {
    path: "busqueda-avanzada",
    loadChildren: () =>
      import("./pages/home/busqueda-avanzada/busqueda-avanzada.module").then(
        (m) => m.BusquedaAvanzadaPageModule
      ),
  },
  {
    path: "registro-cambios",
    loadChildren: () =>
      import("./pages/home/registro-cambios/registro-cambios.module").then(
        (m) => m.RegistroCambiosPageModule
      ),
  },
  {
    path: "ayuda",
    loadChildren: () =>
      import("./pages/home/ayuda/ayuda.module").then((m) => m.AyudaPageModule),
  },
  {
    path: "selector-dv",
    loadChildren: () =>
      import("./pages/home/selector-dv/selector-dv.module").then(
        (m) => m.SelectorDvModule
      ),
  },
  {
    path: "lista-whatsapps-dv",
    loadChildren: () =>
      import(
        "./pages/home/lista-solicitudes-whatsapp/lista-solicitudes-whatsapp.module"
      ).then((m) => m.ListaSolicitudesWhatsappDvModule),
  },
  {
    path: "lista-pedidos-rechazados-riesgo",
    loadChildren: () =>
      import(
        "./pages/home/lista-pedidos-rechazados-riesgo/lista-pedidos-rechazados-riesgo.module"
      ).then((m) => m.ListaPedidosRechazadosRiesgoModule),
  },
  {
    path: "modificar-cita-dv",
    loadChildren: () =>
      import(
        "./componentes/pedido/modificar-cita-dv/modificar-cita-dv.module"
      ).then((m) => m.ModificarCitaDvModule),
  },
  {
    path: "confirmar-cita-dv",
    loadChildren: () =>
      import("./componentes/confirmar-cita-dv/confirmar-cita-dv.module").then(
        (m) => m.ConfirmarCitaDvModule
      ),
  },
  {
    path: "visualizador-ficheros",
    // tslint:disable-next-line:max-line-length
    loadChildren: () =>
      import(
        "./pages/home/visualizador-ficheros/visualizador-ficheros.module"
      ).then((m) => m.VisualizadorFicherosPageModule),
  },
  {
    path: "unir-pedido",
    loadChildren: () =>
      import("./pages/home/unir-pedido/transferir-pedido.module").then(
        (m) => m.TransferirPedidoPageModule
      ),
  },
  {
    path: "confirmar-unir-pedido",
    // tslint:disable-next-line:max-line-length
    loadChildren: () =>
      import(
        "./pages/home/unir-pedido/confirmar-unir-pedido/confirmar-unir-pedido.module"
      ).then((m) => m.ConfirmarUnirPedidoPageModule),
  },
  {
    path: "config-usuarios",
    loadChildren: () =>
      import("./pages/home/config-usuarios/config-usuarios.module").then(
        (m) => m.ConfigUsuariosPageModule
      ),
  },
  {
    path: "rendimiento",
    loadChildren: () =>
      import("./pages/home/rendimiento/rendimiento.module").then(
        (m) => m.RendimientoPageModule
      ),
  },
  {
    path: "pedidos-fecha",
    loadChildren: () =>
      import("./pages/home/pedidos-fecha/pedidos-fecha.module").then(
        (m) => m.PedidosFechaPageModule
      ),
  },
  {
    path: "grafico-pedidos-fecha",
    // tslint:disable-next-line:max-line-length
    loadChildren: () =>
      import(
        "./pages/home/pedidos-fecha/grafico-pedidos-fecha/grafico-pedidos-fecha.module"
      ).then((m) => m.GraficoPedidosFechaPageModule),
  },
  {
    path: "mis-puntuaciones",
    loadChildren: () =>
      import("./pages/home/mis-puntuaciones/mis-puntuaciones.module").then(
        (m) => m.MisPuntuacionesPageModule
      ),
  },
  {
    path: "tendencia",
    loadChildren: () =>
      import("./pages/home/mis-puntuaciones/tendencia/tendencia.module").then(
        (m) => m.TendenciaPageModule
      ),
  },
  {
    path: "asignar-fichero-manual",
    // tslint:disable-next-line:max-line-length
    loadChildren: () =>
      import(
        "./pages/home/visualizador-ficheros/asignar-fichero-manual/asignar-fichero-manual.module"
      ).then((m) => m.AsignarFicheroManualPageModule),
  },
  {
    path: "registro-incidencias",
    // tslint:disable-next-line:max-line-length
    loadChildren: () =>
      import(
        "./pages/home/registro-incidencias/registro-incidencias.module"
      ).then((m) => m.RegistroIncidenciasPageModule),
  },
  {
    path: "activadad-ficheros",
    // tslint:disable-next-line:max-line-length
    loadChildren: () =>
      import(
        "./pages/home/visualizador-ficheros/activadad-ficheros/activadad-ficheros.module"
      ).then((m) => m.ActivadadFicherosPageModule),
  },
  {
    path: "detalles-fichero",
    // tslint:disable-next-line:max-line-length
    loadChildren: () =>
      import(
        "./pages/home/visualizador-ficheros/detalles-fichero/detalles-fichero.module"
      ).then((m) => m.DetallesFicheroPageModule),
  },
  {
    path: "pedido-detalle",
    loadChildren: () =>
      import("./pages/home/pedido-detalle/pedido-detalle.module").then(
        (m) => m.PedidoDetallePageModule
      ),
  },
  {
    path: "info-entrega",
    loadChildren: () =>
      import("./pages/home/info-entrega/info-entrega.module").then(
        (m) => m.InfoEntregaPageModule
      ),
  },
  {
    path: "bulto-detalle",
    loadChildren: () =>
      import(
        "./pages/home/info-entrega/bulto-detalle/bulto-detalle.module"
      ).then((m) => m.BultoDetallePageModule),
  },
  {
    path: "configuracion-gestor-pedidos",
    // tslint:disable-next-line:max-line-length
    loadChildren: () =>
      import(
        "./pages/home/configuracion-gestor-pedidos/configuracion-gestor-pedidos.module"
      ).then((m) => m.ConfiguracionGestorPedidosPageModule),
  },
  {
    path: "busqueda-bultos",
    loadChildren: () =>
      import("./pages/home/busqueda-bultos/busqueda-bultos.module").then(
        (m) => m.BusquedaBultosPageModule
      ),
  },
  {
    path: "info-carga",
    loadChildren: () =>
      import("./pages/home/cargas-balder/info-carga/info-carga.module").then(
        (m) => m.InfoCargaPageModule
      ),
  },
  {
    path: "cargas-balder",
    loadChildren: () =>
      import("./pages/home/cargas-balder/cargas-balder.module").then(
        (m) => m.CargasBalderPageModule
      ),
  },
  {
    path: "mapa-trayecto",
    loadChildren: () =>
      import(
        "./pages/home/cargas-balder/info-carga/mapa-trayecto/mapa-trayecto.module"
      ).then((m) => m.MapaTrayectoPageModule),
  },
  {
    path: "info-carga-cliente",
    loadChildren: () =>
      import(
        "./pages/home/cargas-balder/info-carga/info-carga-cliente/info-carga-cliente.module"
      ).then((m) => m.InfoCargaClientePageModule),
  },
  {
    path: "gestor-descargas",
    loadChildren: () =>
      import("./pages/gestor-descargas/gestor-descargas.module").then(
        (m) => m.GestorDescargasPageModule
      ),
  },
  {
    path: "dialog",
    loadChildren: () =>
      import("./pages/dialog/dialog.module").then((m) => m.DialogPageModule),
  },
  {
    path: "notificaciones-container",
    loadChildren: () =>
      import(
        "./pages/notificaciones-container/notificaciones-container.module"
      ).then((m) => m.NotificacionesContainerPageModule),
  },
  {
    path: "pedido-nuevo",
    loadChildren: () =>
      import("./pages/home/pedido-nuevo/pedido-nuevo.module").then(
        (m) => m.PedidoNuevoPageModule
      ),
  },
  {
    path: "conversaciones-telefono",
    loadChildren: () =>
      import(
        "./pages/home/conversaciones-telefono/conversaciones-telefono.module"
      ).then((m) => m.ConversacionesTelefonoPageModule),
  },
  {
    path: "conversacion-telefono-nueva",
    loadChildren: () =>
      import(
        "./pages/home/conversacion-telefono-nueva/conversacion-telefono-nueva.module"
      ).then((m) => m.ConversacionTelefonoNuevaPageModule),
  },
  {
    path: "conversacion-telefono-edicion",
    loadChildren: () =>
      import(
        "./pages/home/conversacion-telefono-edicion/conversacion-telefono-edicion.module"
      ).then((m) => m.ConversacionTelefonoEdicionPageModule),
  },
  {
    path: "selector-dvs-vencidos-sin-puntos",
    loadChildren: () =>
      import(
        "./pages/home/selector-dvs-vencidos-sin-puntos/selector-dvs-vencidos-sin-puntos.module"
      ).then((m) => m.SelectorDvsVencidosSinPuntosPageModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
